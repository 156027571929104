import React, { useState, useEffect } from "react"
import Button from "../../atoms/button/button"
import Paginator from "../../molecules/paginator/paginator"
import Grid from "../../molecules/grid/grid"
import FilterForm from "../../molecules/filter-form/filter-form"
import SearchNoResult from "../search-no-result/search-no-result"

import { getQueryResult, getTags } from "../../../services/CMS"
import "./search-content.scss"

export default ({ tag }) => {
  const [tagId, setTagId] = useState("")
  const [tagSearchData, setTagSearchData] = useState([])
  const [filters, setFilters] = useState({})
  const [totalEntries, setTotalEntries] = useState(0)
  const [page, setPage] = useState(1)
  const [filterIsVisible, setFilterIsVisible] = useState(false)
  const [tags, setTags] = useState([])

  // useEffect(() => {
  //   const getData = async () => {
  //     setTags(queriedTags.items)
  //   }

  //   getData()

  //   return () => {}
  // }, [])

  useEffect(() => {
    getData()

    return () => {}
  }, [filters, page])

  useEffect(() => {
    setPage(1)
    getData()

    return () => {}
  }, [tag])

  const getData = async () => {
    const queriedTags = await getTags()
    setTags(queriedTags.items)

    if (tag === "todos") {
      const queriedResult = await getQueryResult({
        limit: 30,
        page,
        tipoContenido: filters.tipoContenido,
        categoryLabels: filters.categoryLabels,
        order: filters.order,
        countries: filters.countries,
      })
      setTagSearchData(queriedResult.items)
      setTotalEntries(queriedResult.total)
      setTagId("")
    } else {
      const tagId = queriedTags.items.find(
        el => el.fields.nombreEtiqueta.replace("#", "").toLowerCase() === tag
      )
      const tagsId = tagId ? tagId.sys.id : ""
      const queriedResult = await getQueryResult({
        tagId: tagsId,
        limit: 30,
        page,
        tipoContenido: filters.tipoContenido,
        categoryLabels: filters.categoryLabels,
        order: filters.order,
        countries: filters.countries,
        tag
      })
      setTagSearchData(queriedResult.items)
      setTotalEntries(queriedResult.total)
      setTagId("")
    }
  }

  const onLeftClick = async () => {
    setPage(page - 1)
  }

  const onRightClick = async () => {
    setPage(page + 1)
  }

  return (
    <div className="search-content-container">
      {tagSearchData.length === 0 && <></>}
      {tagId === "noid" && <SearchNoResult tag={tag} />}
      {tagId !== "noid" /* && tagSearchData.length !== 0  */ && (
        <>
          <div className="search-content-content">
            <div className="filter">
              <Button
                styleType="secondary-pink"
                icon="filter-icon"
                iconPosition="left"
                name="Filtros"
                onClick={() => {
                  setFilterIsVisible(!filterIsVisible)
                }}
              />
            </div>
            <h1>Imágenes de {tag}</h1>
            <h2>
              {totalEntries} {totalEntries <= 1 ? "foto" : "fotos"},{" "}
              {totalEntries <= 1 ? "post" : "posts"} y{" "}
              {totalEntries <= 1 ? "vector" : "vectores"}{" "}
              {totalEntries <= 1 ? "relacionada" : "relacionadas"} con
              <span> {tag}</span>
            </h2>
            <Grid data={tagSearchData} />
            {totalEntries > 30 && (
              <Paginator
                total={totalEntries}
                page={page}
                onLeftClick={onLeftClick}
                onRightClick={onRightClick}
              />
            )}
          </div>
          <FilterForm
            data={tagSearchData}
            tags={tags}
            filters={filters}
            open={filterIsVisible}
            onClose={setFilterIsVisible}
            onFilterApply={setFilters}
          />
        </>
      )}
    </div>
  )
}

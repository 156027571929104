import React, { useState, useEffect } from "react"
import Checkbox from "../../atoms/checkbox/checkbox"
import Select from "../../atoms/select/select"
import Radiobox from "../../atoms/radiobox/radiobox"
import ButtonOption from "../../atoms/button-option/button-option"
import {
  getCategoryNames,
  getSubcategoryNames,
  getCountries,
} from "../../../services/CMS"

const orderList = [
  "Más recientes a más antiguos",
  "Más antiguos a más recientes",
  // "Últimos publicados",
]

const ContentTypeSection = ({ filters, onFilterApply }) => {
  const [typeFilter, setTypeFilter] = useState([])

  useEffect(() => {
    console.info("FILTER LENGTH", typeFilter)
    onFilterApply({ ...filters, tipoContenido: typeFilter })
  }, [typeFilter])

  return (
    <>
      <Checkbox
        id="imagen"
        label="Imagen"
        typeFilter={typeFilter}
        onClick={setTypeFilter}
      />
      <Checkbox
        id="fotografia"
        label="Fotografía"
        typeFilter={typeFilter}
        onClick={setTypeFilter}
      />
      <Checkbox
        id="logos"
        label="Logos y recursos"
        typeFilter={typeFilter}
        onClick={setTypeFilter}
      />
      <Checkbox
        id="gif"
        label="GIF y videos"
        typeFilter={typeFilter}
        onClick={setTypeFilter}
      />
      <Checkbox
        id="tipografia"
        label="Tipografías y editables"
        typeFilter={typeFilter}
        onClick={setTypeFilter}
      />
      {/* <Checkbox
        id="todos"
        label="Todos"
        checked={typeFilter.length === 0}
        typeFilter={typeFilter}
        disabled
      /> */}
    </>
  )
}

export default ({ tags, open, onClose, filters, onFilterApply }) => {
  const [categories, setCategories] = useState([""])
  const [subcategories, setSubcategories] = useState([""])
  const [countries, setCountries] = useState([])
  const [subcategoriesSelected, setSubcategoriesSelected] = useState([""])
  const [categorySelected, setCategorySelected] = useState("")
  // const [subcategorySelected, setSubcategorySelected] = useState("")
  const [categoryLabels, setCategoryLabels] = useState([])
  // const [order, setOrder] = useState("")
  const [countriesSelected, setCountriesSelected] = useState([])

  useEffect(() => {
    const getData = async () => {
      const categoryNames = await getCategoryNames()
      // console.info("CATEGORIAS", categoryNames)
      setCategories(["", ...categoryNames.items.map(el => el.fields.nombre)])

      const subcategoryNames = await getSubcategoryNames()
      // console.info("SUBCATEGORIAS", subcategoryNames)
      setSubcategories(subcategoryNames)

      const countryEntries = await getCountries()
      setCountries(countryEntries.items)
    }

    getData()

    return () => {}
  }, [])

  useEffect(() => {
    setSubcategoriesSelected(
      subcategories.items &&
        subcategories.items
          .filter(el => el.fields.categoria.fields.nombre === categorySelected)
          .map(el => el.fields.nombre)
    )
    return () => {}
  }, [categorySelected])

  const handleCategoryChange = category => {
    setCategorySelected(category)
    onFilterApply({
      ...filters,
      categoryLabels:
        category === ""
          ? undefined
          : [
              tags.filter(
                el =>
                  el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
                  category.replace(/ /g, "").toLowerCase()
              )[0].sys.id,
            ],
    })
    setCategoryLabels(
      category === ""
        ? undefined
        : [
            tags.filter(
              el =>
                el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
                category.replace(/ /g, "").toLowerCase()
            )[0].sys.id,
          ]
    )
  }

  const handleSubcategoryChange = subcategory => {
    const subcategoryId = tags.filter(
      el =>
        el.fields.nombreEtiqueta.replace("#", "") ===
        subcategory.replace(/ /g, "")
    )[0].sys.id
    // setSubcategorySelected(subcategory)
    if (categoryLabels.length === 0) {
      onFilterApply(
        subcategory === ""
          ? undefined
          : {
              ...filters,
              categoryLabels: categoryLabels.concat(subcategoryId),
            }
      )
      setCategoryLabels(
        subcategory === "" ? undefined : categoryLabels.concat(subcategoryId)
      )
    } else {
      onFilterApply({
        ...filters,
        categoryLabels:
          subcategory === ""
            ? undefined
            : categoryLabels.slice(0, 1).concat(subcategoryId),
      })
      setCategoryLabels(
        subcategory === ""
          ? undefined
          : categoryLabels.slice(0, 1).concat(subcategoryId)
      )
    }
  }

  const handleOrderChange = option => {
    // console.info("ORDER", order)
    onFilterApply({ ...filters, order: option })
    // setOrder(option)
  }

  const handleCountryChange = country => {
    // console.info("COUNTRY ENTRIES", countries)
    const countryId =
      countries.filter(el => el.fields.nombreDelPais === country).length > 0
        ? countries.filter(el => el.fields.nombreDelPais === country)[0].sys.id
        : ""

    console.info("COUNTRY LIST", countriesSelected)

    if (countriesSelected.length === 0) {
      // console.info(":D")
      onFilterApply({ ...filters, countries: [countryId] })
      setCountriesSelected([countryId])
    } else if (countriesSelected.includes(countryId)) {
      onFilterApply({
        ...filters,
        countries: countriesSelected.filter(el => el !== countryId),
      })
      setCountriesSelected(countriesSelected.filter(el => el !== countryId))
    } else {
      onFilterApply({
        ...filters,
        countries: countriesSelected.concat(countryId),
      })
      setCountriesSelected(countriesSelected.concat(countryId))
    }
  }

  return (
    <div className={`filter-content ${open ? "" : "hidden"}`}>
      <div className="filter-header">
        <span>Filtros</span>{" "}
        <div className="filter-close-icon" onClick={() => onClose(!open)} />
      </div>
      <div className="filter-section">
        <span>Tipo de contenido</span>
        {/* checkboxes */}
        <ContentTypeSection onFilterApply={onFilterApply} />
      </div>
      <div className="filter-section">
        <span>Categoría</span>
        {/* select */}
        <Select
          defaultValue="Selecciona una opción"
          options={categories}
          onChange={option => handleCategoryChange(option)}
        />
      </div>
      <div className="filter-section">
        <span> Subcategoría</span>
        {/* select */}
        <Select
          defaultValue="Selecciona una opción"
          options={
            subcategoriesSelected && subcategoriesSelected.length > 0
              ? subcategoriesSelected
              : [""]
          }
          onChange={option => handleSubcategoryChange(option)}
        />
      </div>
      <div className="filter-section">
        <span>Ordenar por</span>
        {/* radiobox */}
        <Radiobox
          name="filter-order"
          options={orderList}
          onChange={option => handleOrderChange(option)}
        />
      </div>
      <div className="filter-section">
        <span>País</span>
        {/* rounded button */}
        <ButtonOption
          options={[
            "Colombia",
            "Ecuador",
            "Argentina",
            "República Dominicana",
            "Bolivia",
            "Chile",
            "Paraguay",
            "Uruguay",
            "Perú",
            "Puerto Rico",
          ]}
          onChange={country => handleCountryChange(country)}
        />
      </div>
    </div>
  )
}

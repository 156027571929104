import React, { useState } from "react"

import "./button-option.scss"

const Button = ({ option, onClick }) => {
  const [isSelected, setIsSelected] = useState(false)

  return (
    <button
      className={isSelected ? "selected" : ""}
      onClick={() => {
        setIsSelected(!isSelected)
        onClick(option)
      }}
    >
      {option}
    </button>
  )
}

export default ({ options, onChange }) => {
  return (
    <div className="button-option-wrapper">
      {options.map((option, index) => (
        <Button key={index} option={option} onClick={onChange} />
      ))}
    </div>
  )
}

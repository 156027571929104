import React, { useState } from "react"

import "./checkbox.scss"

export default ({ id, label, checked, typeFilter, onClick, disabled }) => {
  const [value, setValue] = useState(checked ? id : "")

  // const getTipoContenido = () => {
  //   if (label === "Imagen" || label === "Fotografía") {
  //     return label
  //   }

  //   if (label === "GIF y videos") {
  //     return ["Video", "Gif"]
  //   }

  //   if (label === "Todos") {
  //     return undefined
  //   }
  // }

  const handleChange = id => {
    value === id ? setValue("") : setValue(id)
  }

  return (
    <form className="checkbox-wrapper">
      <label className="checkbox-label" htmlFor={id}>
        {label}
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={value === id}
          // disabled={disabled}
          onChange={() => handleChange(id)}
          onClick={
            () =>
              onClick(
                value !== id
                  ? typeFilter.concat(label)
                  : typeFilter.filter(el => el !== label)
              )
            // onClick(
            //   value !== id
            //     ? typeFilter.concat(getTipoContenido()).flat()
            //     : typeFilter.filter(el => {
            //         if (Array.isArray(getTipoContenido())) {
            //           console.info("VIDEO AND GIF", el, getTipoContenido()[0])
            //           return (
            //             el !== getTipoContenido()[0] &&
            //             el !== getTipoContenido()[1]
            //           )
            //         }
            //         console.info("REST")
            //         return el !== getTipoContenido()
            //       })
            // )
          }
        />
        <span className="checkbox-custom" />
      </label>
    </form>
  )
}

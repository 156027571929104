import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/templates/layout/layout"
import Header from "../components/organisms/header/header"
import SearchContent from "../components/organisms/search-content/search-content"
import SEO from "../components/seo"

import "../styles/index.scss"

const SearchPage = ({ location }) => {
  console.info("LOCATION", location)

  return (
    <>
      <SEO lang="es" title="Home" />
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Header />
        <Layout>
          <Router basepath="/buscar" style={{ display: "flex", flexGrow: 1 }}>
            <SearchContent path="/:tag" />
          </Router>
        </Layout>
      </div>
    </>
  )
}

export default SearchPage

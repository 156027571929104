import React from "react"

import "./paginator.scss"

export default ({ total, page, onLeftClick, onRightClick }) => {
  const totalPages = Math.ceil(total / 30)
  const validLeft = page === 1 ? "disabled" : ""
  const validRight = page === totalPages ? "disabled" : ""

  return (
    <div className="paginator">
      <label
        className={`left-arrow ${validLeft}`}
        onClick={() => onLeftClick()}
      />
      <div className="currentPage">{page}</div>
      <label
        className={`right-arrow ${validRight}`}
        onClick={() => onRightClick()}
      />
      <div className="pages">de {Math.ceil(total / 30)}</div>
    </div>
  )
}

import React from "react"

import "./search-no-result.scss"

export default ({ tag }) => {
  return (
    <div className="search-no-result-container">
      <h1>¡Oops!</h1>
      <p>
        No hemos encontrado resultados para "{tag}" <br />
        Intenta realizar una nueva búsqueda
      </p>
    </div>
  )
}
